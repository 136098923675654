import { Wrapper } from "./Button.styles"

type Props = {
  disabled?: boolean
  children: any
  action?: () => void
}

export const Button = ({ children, disabled, action }: any) => {
  const handleAction = () => {
    if(disabled) return
    action()
  }
  return <Wrapper 
  onClick={handleAction}
  isDisabled={disabled}>
    <p>
      {children}
    </p>
  </Wrapper>
}