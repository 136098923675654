import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 305px);
  background-color: #4D3277;
`;

export const FormContainer = styled.div`
  max-width: 350px;
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 16px;
  border: 1px solid #cccccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

export const Headers = styled.div`
  padding: 20px;
  height: 100px;
  background-color: #412A63;
`;

export const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  padding: 10px;
  padding-right: 40px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  box-sizing: border-box;
`;

export const PasswordIcon = styled.span`
  position: absolute;
  top: 70%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 12px; /* Altere este valor para ajustar o tamanho do ícone */
`;

export const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #61C9B0;
  color: white;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #93D4C4;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 10px;
`;

export const Label = styled.label`
  display: block;
  font-size: 13px; /* Altere este valor para ajustar o tamanho do ícone */
  margin-bottom: 5px;
`;
