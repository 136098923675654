import styled from "styled-components";

export const Wrapper = styled.div<{
  isDisabled?: boolean
}>`
  ${
    ({theme, isDisabled}) => `
      background-color: ${isDisabled ? theme.gray : theme.green};
      height: 5.6rem;
      width: 100%;
      border-radius: .8rem;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      opacity: ${isDisabled ? .6 : 1};

      p {
        font-size: 1.6rem;
        font-weight: 600;
        color: ${theme.white};
        font-family: 'Inter', sans serif;
        user-select: none;
      }

      transition: 256ms ease-in-out;
      :hover {
        opacity: .7;
      }

      :active {
        transform: scale(.95);
      }
    `
  }

`