import React from 'react';
import { Link } from 'react-router-dom';
import {Title, LogoContainer, ContentContainer } from './Terms.styles';



const Terms: React.FC = () => {
  return (
    <ContentContainer>
      <LogoContainer>
        <Link to="/">
          <img src="/logosplash.png" alt="Logo" width="15%" />
        </Link>
      </LogoContainer>
      <Title>Política de Privacidade</Title>

<br></br>

<p>Este aplicativo é mantido e operado por C C DELGADO- BENTO-APP.COM AGENCIA DE SERVIÇOS ONLINE.</p>

<p>Nós coletamos e utilizamos alguns dados pessoais que pertencem àqueles que utilizam nosso aplicativo. Ao fazê-lo, agimos na qualidade de controlador desses dados e estamos sujeitos às disposições da Lei Federal n. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais - LGPD).</p>

<p>Nós cuidamos da proteção de seus dados pessoais e, por isso, disponibilizamos esta política de privacidade, que contém informações importantes sobre:</p>

<ul>
  <li>Quem deve utilizar nosso aplicativo</li>
  <li>Quais dados coletamos e o que fazemos com eles;</li>
  <li>Seus direitos em relação aos seus dados pessoais; e</li>
  <li>Como entrar em contato conosco.</li>
</ul>
<br></br>
<h2>1. Quem deve utilizar nosso aplicativo</h2>

<p>Nosso aplicativo somente deve ser utilizado por pessoas que tenham, pelo menos,
18 (dezoito) anos de idade, sendo que a utilização por pessoa com menos de 18
(dezoito) anos somente será possível mediante o consentimento de pelo menos um
de seus pais ou responsável.</p>
<br></br>
<h2>2. Dados que coletamos e motivos da coleta</h2>

<p>Nosso aplicativo coleta e utiliza alguns dados pessoais de nossos usuários, de acordo com o disposto nesta seção.</p>

<h3>1. Dados pessoais fornecidos expressamente pelo usuário</h3>

<p>Nós coletamos os seguintes dados pessoais que nossos usuários nos fornecem
expressamente ao utilizar nosso aplicativo:</p>

<ul>
  <li>Pessoa física: Nome e sobrenome, CPF, Telefone de contato, e-mail, Localização/endereço (enquanto estiver usando o app), Dados do cartão</li>
  <li>Pessoa Jurídica: todos os dados de pessoa física acima citados e CNPJ, Nome fantasia e razão social, Conta bancária: número do banco, agência e conta (precisa estar atrelado ao titular do cnpj/cpf)</li>
</ul>

<p>A coleta destes dados ocorre nos seguintes momentos:</p>

<ul>
  <li>Quando o cliente faz o cadastro no aplicativo</li>
  <li>Quando ele precisa cadastrar um novo cartão</li>
</ul>

<p>Os dados fornecidos por nossos usuários são coletados com as seguintes finalidades:</p>

<ul>
  <li>Pessoa física: Para que os clientes possam entrar no aplicativo e fazer parte da comunidade, Para que ele possa entrar em contato com o suporte, Para que possa comprar os produtos disponíveis apenas na plataforma</li>
  <li>Pessoa Jurídica: quando for se cadastrar no app, para que consiga vender seus produtos em nossa plataforma. É necessário informar a conta bancária para receber os valores proporcionais das vendas realizadas na plataforma</li>
</ul>
<br></br>
<h3>2. Dados sensíveis</h3>

<p>Não serão coletados dados sensíveis de nossos usuários, assim entendidos aqueles definidos nos arts. 11 e seguintes da Lei de Proteção de Dados Pessoais. Assim, não haverá coleta de dados sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.</p>

<p>3. Coleta de dados não previstos expressamente</p>

<p>Eventualmente, outros tipos de dados não previstos expressamente nesta Política de Privacidade poderão ser coletados, desde que sejam fornecidos com o consentimento do usuário, ou, ainda, que a coleta seja permitida com fundamento em outra base legal prevista em lei. Em qualquer caso, a coleta de dados e as atividades de tratamento dela decorrentes serão informadas aos usuários do aplicativo.</p>
<br></br>
<h2>3. Compartilhamento de dados pessoais com terceiros</h2>

<p>Nós compartilhamos alguns dos dados pessoais mencionados nesta seção com terceiros.</p>

<p>Os dados compartilhados são os seguintes:</p>

<ul>
  <li>Alguns dados compartilhamos com o gateway de pagamento da Safe2Pay.</li>
  <li>O serviço de localização compartilhamos com a Google.</li>
</ul>

<p>Estes dados são compartilhados pelas seguintes razões e para as seguintes finalidades:</p>

<ul>
  <li>Usamos o compartilhamento de dados com a finalidade de fazer o split de pagamento, tendo em vista que nosso aplicativo será apenas um marketplace.</li>
  <li>O serviço de localização é compartilhado com a Google para ajudar a encontrar as lojas mais próximas do cliente e para conseguirmos calcular o valor de entrega.</li>
</ul>

<p>Além das situações aqui informadas, é possível que compartilhemos dados com terceiros para cumprir alguma determinação legal ou regulatória, ou, ainda, para cumprir alguma ordem expedida por autoridade pública. Em qualquer caso, o compartilhamento de dados pessoais observará todas as leis e regras aplicáveis, buscando sempre garantir a segurança dos dados de nosso usuários, observados os padrões técnicos empregados no mercado.</p>
<br></br>
<h2>4. Por quanto tempo seus dados pessoais serão armazenados</h2>

<p>Os dados pessoais coletados pelo aplicativo são armazenados e utilizados por período de tempo que corresponda ao necessário para atingir as finalidades elencadas neste documento e que considere os direitos de seus titulares, os direitos do controlador do aplicativo e as disposições legais ou regulatórias aplicáveis. Uma vez expirados os períodos de armazenamento dos dados pessoais, eles são removidos de nossas bases de dados ou anonimizados, salvo nos casos em que houver a possibilidade ou a necessidade de armazenamento em virtude de disposição legal ou regulatória.</p>
<br></br>
<h2>5. Bases legais para o tratamento de dados pessoais</h2>

<p>Cada operação de tratamento de dados pessoais precisa ter um fundamento jurídico, ou seja, uma base legal, que nada mais é que uma justificativa que a autorize, prevista na Lei Geral de Proteção de Dados Pessoais. Todas as nossas atividades de tratamento de dados pessoais possuem uma base legal que as fundamenta, dentre as permitidas pela legislação. Mais informações sobre as bases legais que utilizamos para operações de tratamento de dados pessoais específicas podem ser obtidas a partir de nossos canais de contato, informados ao final desta Política.</p>
<br></br>
<h2>6. Direitos do usuário</h2>

<p>O usuário do aplicativo possui os seguintes direitos, conferidos pela Lei de Proteção de Dados Pessoais:</p>

<ul>
  <li>Confirmação da existência de tratamento;</li>
  <li>Acesso aos dados;</li>
  <li>Correção de dados incompletos, inexatos ou desatualizados;</li>
  <li>Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na lei;</li>
  <li>Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;</li>
  <li>Eliminação dos dados pessoais tratados com o consentimento do titular, exceto nos casos previstos em lei;</li>
  <li>Informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;</li>
  <li>Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</li>
  <li>Revogação do consentimento.</li>
</ul>

<p>É importante destacar que, nos termos da LGPD, não existe um direito de eliminação de dados tratados com fundamento em bases legais distintas do consentimento, a menos que os dados seja desnecessários, excessivos ou tratados em desconformidade com o previsto na lei.</p>

<h3>1. Como o titular pode exercer seus direitos</h3>

<p>Para garantir que o usuário que pretende exercer seus direitos é, de fato, o titular dos dados pessoais objeto da requisição, poderemos solicitar documentos ou outras informações que possam auxiliar em sua correta identificação, a fim de resguardar nossos direitos e os direitos de terceiros. Isto somente será feito, porém, se for absolutamente necessário, e o requerente receberá todas as informações relacionadas.</p>
<br></br>
<h2>7. Medidas de segurança no tratamento de dados pessoais</h2>

<p>Empregamos medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, extravio ou alteração desses dados.</p>

<p>As medidas que utilizamos levam em consideração a natureza dos dados, o contexto e a finalidade do tratamento, os riscos que uma eventual violação geraria para os direitos e liberdades do usuário, e os padrões atualmente empregados no mercado por empresas semelhantes à nossa.</p>

<p>Entre as medidas de segurança adotadas por nós, destacamos as seguintes:</p>

<ul>
  <li>Armazenamento de senha criptografado</li>
  <li>Dados bancários criptografados</li>
  <li>Acesso restrito a API e banco de dados</li>
</ul>

<p>Ainda que adotemos tudo o que está ao nosso alcance para evitar incidentes de segurança, é possível que ocorra algum problema motivado exclusivamente por um terceiro - como em caso de ataques de hackers ou crackers ou, ainda, em caso de culpa exclusiva do usuário, que ocorre, por exemplo, quando ele mesmo transfere seus dados a terceiros. Assim, embora sejamos, em geral, responsáveis pelos dados pessoais que tratamos, nos eximimos de responsabilidade caso ocorra uma situação excepcional como essas, sobre as quais não temos nenhum tipo de controle.</p>

<p>De qualquer forma, caso ocorra qualquer tipo de incidente de segurança que possa gerar risco ou dano relevante para qualquer de nossos usuários, comunicaremos os afetados e a Autoridade Nacional de Proteção de Dados acerca do ocorrido, em conformidade com o disposto na Lei Geral de Proteção de Dados.</p>
<br></br>
<h2>8. Reclamação a uma autoridade de controle</h2>

<p>Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, os titulares de dados pessoais que se sentirem, de qualquer forma, lesados, podem apresentar reclamação à Autoridade Nacional de Proteção de Dados.</p>
<br></br>
<h2>9. Alterações nesta política</h2>

<p>A presente versão desta Política de Privacidade foi atualizada pela última vez em: 13/05/2024. Reservamo-nos o direito de modificar, a qualquer momento, as presentes normas, especialmente para adaptá-las às eventuais alterações feitas em nosso aplicativo, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes. Sempre que houver uma modificação, nossos usuários serão notificados acerca da mudança.</p>
<br></br>
<h2>10. Como entrar em contato conosco</h2>

<p>Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, entre em contato com nosso Encarregado de Proteção de Dados Pessoais, por algum dos canais mencionados abaixo:</p>

<ul>
  <li>E-mail: allyne.melo@bento-app.com</li>
  <li>Telefone: +55 41 995401679</li>
  <li>Endereço postal: Rua Maria Luiza Bazzani Natal 381, Casa 63</li>
</ul>


    </ContentContainer>
  );
}

export default Terms;
