import styled from "styled-components";

export const Wrapper = styled.div`
${({theme}) => `

  display: flex;
  padding-left: 16rem;
  padding-right: 10rem;
  justify-content: space-between;
  min-height: 58rem;
  position: relative;
  width: 100vw;



  background-image: linear-gradient(to right, ${theme.purple} 44.97%, ${theme.pinkbg} 115%);
  .splash {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(/imgs/splash_bg.svg);
    background-size: cover;
    backgroundposition: center;
    width: 100vw;
    height: 100%;
  }

  .label {
    z-index: 1;
    max-width: 52rem;
    font-size: 4.8rem;
    font-weight: 600;
    align-self: center;
    color: ${theme.white};
    text-align: right;
    line-height: 7.2rem;
    letter-spacing: -2%;
    margin-right: 8rem;
    span {
      font-size: 5.8rem;
      font-weight: 700;
      line-height: 8.7rem;
    }
  }

  .mobile {
    z-index: 1;
    position: relative;    
    width: 52rem;
    height: 52.9rem;

    flex-shrink: 0;
    .bg {
      width: 50rem;
      height: 52.9rem;
      position: absolute;
    }
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div.box {
      background-color: ${theme.white};
      width: 40rem;
      height: 35.3rem;
      border-radius: 3.2rem;
      padding: 2.4rem;
      z-index: 2;
      margin-left: -1rem;
      margin-top: 6rem;
      box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);

      .header {
        padding-bottom: 1.6rem;
        border-bottom: .1rem solid rgba(0, 0, 0, 0.2);
        margin-bottom: 2.4rem;
        .title {
          color: ${theme.purple};
          font-size: 2.2rem;
          line-height: 3.3rem;
          font-weight: 600;
          margin-bottom: .8rem;
        }
        .description {
          font-size: 1.7rem;
          line-height: 2.5rem;
          font-weight: 500;
          letter-spacing: -.05rem;
          color: ${theme.darkgray};
        }
      }

      .image__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 1.6rem;
        margin-bottom: 2.4rem;
        img {
          width: 17rem;
          cursor: pointer;
          transition: 512ms ease-in-out;
          :hover {
            opacity: .8;
          }
        }
      }

      

      .call {
        text-align: center;
        font-size: 1.6rem;
        font-weight: 500;
        color: ${theme.darkgray};
        line-height: 2.4rem;
        span {
          font-weight: 600;
        }
        margin-bottom: 1.6rem;
      }

    }
  }

  @media (max-width: 1333px) {    
    flex-wrap: wrap;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 13.8rem;
    row-gap: 8rem;
    flex-direction: column;
    align-items: center;
    .label {
      margin-right: 0;
      max-width: 90vw;
    }
    .mobile {
      width: 100%;
      flex-grow: 1;
      .bg {
        max-width: 90vw;
      }

      div.box {
        max-width: 70vw;
      }
    }
  }


  @media (max-width: 625px) {    
    .label {
      margin-right: 0;
      max-width: 90vw;
      font-size: 3.2rem;
      line-height: 4.2rem;
      span {
        font-size: 4rem;
        line-height: 5rem;
      }
    }
  }

  @media (max-width: 570px) {    
    padding-left: 0;
    padding-right: 0;
    .label {
      margin-right: 0;
      max-width: 90vw;
      font-size: 2.4rem;
      line-height: 4rem;
      span {
        font-size: 3.2rem;
        line-height: 5rem;
      }
    }

    .call {
      font-size: 1.4rem;
    }

    .mobile {
      width: 100vw;
      div.box {
        height: unset;
        margin-top: 8rem;
        width: 100vw;
      }
      .bg {
        height: 65rem;        
        max-width: 100vw;
      }
      .image__row {
        max-width: 70vw;
        position: relative;
        row-gap: 1rem;
        flex-direction: row;
        img {
          width: unset;
          flex-shrink: 1;
          height: auto;
          max-width: 45%;
        }
      }
    }
  }

  @media (max-width: 420px) {
    .bg {
      opacity: 0;
    }

    .mobile {
      div.box {
        margin-top: 0;
      }
    }
  }

`}

`

