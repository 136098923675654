import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as S from './Reset.styles';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from "../../components/Footer";

const API_KEY = 'AIzaSyDPHXJAdPzOIe13oX-XXNIW9TpsIVDE9Y8';

const ChangePasswordPage: React.FC = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const oobCode = searchParams.get('oobCode');

  useEffect(() => {
    if (!oobCode) {
      navigate('/');
    }
  }, [oobCode, navigate]);

  const handleChangePassword = () => {
    if (password !== confirmPassword) {
      setError('As senhas não coincidem');
      return;
    }

    if (password.length < 8 || !/[a-zA-Z]/.test(password)) {
      setError('A senha deve ter pelo menos 8 caracteres e conter pelo menos uma letra');
      return;
    }

    axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:resetPassword?key=${API_KEY}`, {
      oobCode: oobCode,
      newPassword: password
    })
      .then(response => {
        console.log('Senha alterada com sucesso:', response.data);
        toast.success('Senha alterada com sucesso');
        navigate('/');
      })
      .catch(error => {
        console.error('Erro ao alterar senha:', error);
        toast.error('Erro ao alterar senha. Por favor, tente novamente.');
      });
  };

  const togglePasswordVisibility = (field: string) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else if (field === 'confirmPassword') {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  return (
    <div>
      <S.Headers>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src="/logosplash.png" alt="Logo" width="150px" />
        </div>
      </S.Headers>
      <S.Container>
        <S.FormContainer>
          <S.Title>Alterar Senha</S.Title>
          <S.InputWrapper>
            <S.Label htmlFor="password">Nova Senha</S.Label>
            <S.Input
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <S.PasswordIcon onClick={() => togglePasswordVisibility('password')}>
              {showPassword ? <FiEyeOff /> : <FiEye />}
            </S.PasswordIcon>
          </S.InputWrapper>
          <S.InputWrapper>
            <S.Label htmlFor="confirmPassword">Repetir Senha</S.Label>
            <S.Input
              type={showConfirmPassword ? 'text' : 'password'}
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <S.PasswordIcon onClick={() => togglePasswordVisibility('confirmPassword')}>
              {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
            </S.PasswordIcon>
          </S.InputWrapper>
          {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
          <S.Button onClick={handleChangePassword}>Salvar Nova Senha</S.Button>
        </S.FormContainer>
      </S.Container>
      <Footer />
    </div>
  );
};

export default ChangePasswordPage;
