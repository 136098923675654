import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.9rem;
  padding-top: 1.9rem;
  padding-left: 16rem;
  padding-right: 16rem;


   
  
  .contact {
    display: flex;
    align-items: center;
    
    >div {
      cursor: pointer;
      transition: 512ms ease-in-out;
      display: flex;
      align-items: center;
      
      &:hover {
        opacity: .5;
      }

      &:not(:last-child) {
        border-right: .1rem solid ${({theme}) => theme.border};        
        padding-right: 2rem;
        margin-right: 2rem;
      }
      
      .summary {
        margin-left: .8rem;

        .label {
          font-size: 1.6rem;
          font-weight: 600;
          color: ${({theme}) => theme.green};
        }

        .description {
          font-family: 'Rubik', sans serif;
          font-size: 1.4rem;
          font-weight: 400;
          color: ${({theme}) => theme.lightgray};
        }
      }
    }
  }
  
  @media (max-width: 975px) {
    padding-left: 5vw;
    padding-right: 5vw;
  }
  
  @media (max-width: 730px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 2rem;
    
    .contact {
      align-self: flex-end;
    }
  }

  @media (max-width: 450px) {
    .contact {
      flex-direction: column;
      align-items: flex-start;
      width: 90vw;
      row-gap: 1rem;
      
      >div {
        &:first-child {
          margin-right: 0;
          padding-right: 0;
          border-right: none;
        }
      }
    }
  }
`
