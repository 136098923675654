export const PROJECT_ICONS = {
  logo: <svg width="249" height="72" viewBox="0 0 249 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M76.8145 53.5241V21.1191H91.3566C93.1049 21.1191 94.6783 21.4278 96.0769 22.045C97.5072 22.6622 98.6356 23.5881 99.4621 24.8226C100.32 26.0262 100.749 27.523 100.749 29.313C100.749 31.2264 100.304 32.8158 99.4144 34.0811C98.5562 35.3465 97.4596 36.1797 96.1245 36.5809C97.2371 36.7352 98.2383 37.1827 99.1283 37.9234C100.05 38.6332 100.781 39.5745 101.322 40.7473C101.862 41.92 102.132 43.2471 102.132 44.7285C102.132 47.7838 101.179 50.0213 99.2714 51.4409C97.396 52.8297 94.9008 53.5241 91.7858 53.5241H76.8145ZM83.0128 47.969H90.9275C92.4215 47.969 93.5499 47.6449 94.3127 46.9968C95.0756 46.3487 95.457 45.3148 95.457 43.8952C95.457 42.5373 95.012 41.5343 94.122 40.8862C93.232 40.2072 92.1672 39.8677 90.9275 39.8677H83.0128V47.969ZM83.0128 34.2663H90.117C92.6917 34.2663 93.979 32.9392 93.979 30.2851C93.979 29.0815 93.6293 28.1865 92.93 27.6001C92.2625 26.9829 91.3249 26.6743 90.117 26.6743H83.0128V34.2663Z" fill="#DF829E" />
    <path d="M113.701 53.6032C110.332 53.6032 107.598 52.6465 105.501 50.733C103.403 48.7887 102.354 45.8568 102.354 41.9374C102.354 39.4993 102.815 37.9323 103.736 36.204C104.69 34.4449 105.993 33.1024 107.646 32.1766C109.299 31.2198 111.206 31.1716 113.368 31.1716C115.529 31.1716 117.357 31.2507 118.851 32.2691C120.376 33.2567 121.537 34.6301 122.331 36.3892C123.126 38.1483 123.523 39.6536 123.523 41.9374V43.1873H108.266C108.425 45.255 108.997 46.6747 109.982 47.4462C111 48.1869 112.271 48.5573 113.797 48.5573C115.195 48.5573 116.34 48.2332 117.23 47.5851C118.12 46.937 118.914 45.9649 119.614 44.6687L124 47.6777C122.76 49.8072 121.282 51.3348 119.566 52.2607C117.881 53.1557 115.926 53.6032 113.701 53.6032ZM108.504 38.7432H117.611C117.039 36.0891 115.593 35.2782 113.272 35.2782C112.001 35.2782 110.968 35.5868 110.173 36.204C109.378 36.8213 108.822 37.3235 108.504 38.7432Z" fill="#DF829E" />
    <path d="M124.979 53.5241V31.211H130.319L130.653 33.7108C131.288 32.9084 132.099 32.1986 133.084 31.5813C134.101 30.9641 135.293 30.6555 136.66 30.6555C139.044 30.6555 140.983 31.3961 142.477 32.8775C144.003 34.3589 144.766 36.5501 144.766 39.4511V53.5241H138.758V40.8862C138.758 39.2505 138.424 38.016 137.757 37.1827C137.121 36.3495 136.247 35.9328 135.135 35.9328C133.927 35.9328 132.925 36.3649 132.131 37.229C131.368 38.0623 130.986 39.3894 130.986 41.2102V53.5241H124.979Z" fill="#DF829E" />
    <path d="M155.574 53.5171C154.049 53.5171 152.745 53.3474 151.665 53.0079C150.616 52.6993 149.805 52.0975 149.233 51.2025C148.693 50.3075 148.422 49.0422 148.422 47.4065V36.2106H144.131V31.211H148.422V23.4801H154.382V31.211H160.581V36.2106H154.382V45.8788C154.382 46.8355 154.621 47.4836 155.098 47.8231C155.606 48.1626 156.321 48.3323 157.243 48.3323C157.879 48.3323 158.499 48.2398 159.103 48.0546C159.738 47.8386 160.231 47.638 160.581 47.4528V52.7302C159.977 52.9771 159.214 53.1622 158.292 53.2857C157.402 53.44 156.496 53.5171 155.574 53.5171Z" fill="#DF829E" />
    <path d="M171.2 54.0333C169.07 54.0333 167.147 53.555 165.43 52.5982C163.746 51.6415 162.411 50.2836 161.425 48.5245C160.472 46.7653 159.995 44.713 159.995 42.3675C159.995 39.9912 160.472 37.9389 161.425 36.2106C162.411 34.4515 163.746 33.0935 165.43 32.1368C167.147 31.1492 169.07 30.6555 171.2 30.6555C173.361 30.6555 175.284 31.1492 176.969 32.1368C178.653 33.0935 179.973 34.4515 180.926 36.2106C181.911 37.9389 182.404 39.9912 182.404 42.3675C182.404 44.713 181.911 46.7653 180.926 48.5245C179.973 50.2836 178.653 51.6415 176.969 52.5982C175.284 53.555 173.361 54.0333 171.2 54.0333ZM171.2 48.7559C172.725 48.7559 173.965 48.2159 174.919 47.1357C175.904 46.0247 176.397 44.4353 176.397 42.3675C176.397 40.2689 175.904 38.6795 174.919 37.5994C173.965 36.4883 172.725 35.9328 171.2 35.9328C169.674 35.9328 168.434 36.4883 167.481 37.5994C166.559 38.6795 166.098 40.2689 166.098 42.3675C166.098 44.4353 166.559 46.0247 167.481 47.1357C168.434 48.2159 169.674 48.7559 171.2 48.7559ZM169.388 22.1457H173.107L179.114 28.9045L177.303 30.9414L171.247 26.775L165.144 30.9414L163.333 28.9045L169.388 22.1457Z" fill="#DF829E" />
    <path d="M184.086 46.9764C184.086 50.2808 186.792 53.3277 191.232 53.3277C194.319 53.3277 196.306 51.8686 197.363 50.195C197.363 51.0103 197.448 52.169 197.575 52.7269H202.733C202.607 51.9973 202.48 50.4954 202.48 49.3796V38.9944C202.48 34.7459 200.027 30.9695 193.431 30.9695C187.849 30.9695 184.847 34.6172 184.509 37.9216L189.498 38.9944C189.667 37.1491 191.02 35.5613 193.473 35.5613C195.841 35.5613 196.983 36.8058 196.983 38.3078C196.983 39.0373 196.602 39.6381 195.418 39.8098L190.302 40.5822C186.834 41.0972 184.086 43.2 184.086 46.9764ZM192.416 49.0792C190.598 49.0792 189.71 47.8776 189.71 46.6331C189.71 45.0024 190.851 44.187 192.289 43.9724L196.983 43.2429V44.187C196.983 47.9205 194.784 49.0792 192.416 49.0792Z" fill="#61C9B0" />
    <path d="M210.39 60.8805V50.667C211.404 52.0832 213.519 53.2419 216.394 53.2419C222.272 53.2419 226.204 48.5213 226.204 42.1271C226.204 35.8617 222.695 31.1411 216.606 31.1411C213.476 31.1411 211.151 32.5573 210.22 34.188V31.6132H204.766V60.8805H210.39ZM220.665 42.17C220.665 45.9465 218.381 48.1351 215.506 48.1351C212.631 48.1351 210.305 45.9036 210.305 42.17C210.305 38.4365 212.631 36.2479 215.506 36.2479C218.381 36.2479 220.665 38.4365 220.665 42.17Z" fill="#61C9B0" />
    <path d="M232.898 60.8805V50.667C233.913 52.0832 236.027 53.2419 238.903 53.2419C244.78 53.2419 248.713 48.5213 248.713 42.1271C248.713 35.8617 245.203 31.1411 239.114 31.1411C235.985 31.1411 233.659 32.5573 232.729 34.188V31.6132H227.274V60.8805H232.898ZM243.173 42.17C243.173 45.9465 240.89 48.1351 238.015 48.1351C235.139 48.1351 232.814 45.9036 232.814 42.17C232.814 38.4365 235.139 36.2479 238.015 36.2479C240.89 36.2479 243.173 38.4365 243.173 42.17Z" fill="#61C9B0" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M28.2458 58.4123C28.2396 58.4088 28.2333 58.4053 28.2271 58.4018C23.6318 55.796 20.724 52.9893 18.9807 50.1998C17.2091 47.365 16.8153 44.8267 16.8153 43.0952C16.8153 37.6366 21.5215 33.9363 26.3902 33.9363C28.2782 33.9363 29.8877 34.539 31.1083 35.2117C32.3289 34.539 33.9384 33.9363 35.8264 33.9363C40.6951 33.9363 45.4013 37.6366 45.4013 43.0952C45.4013 44.8267 45.0075 47.365 43.2359 50.1998C41.4926 52.9893 38.5848 55.796 33.9895 58.4018C33.9833 58.4053 33.977 58.4088 33.9708 58.4123C33.0833 58.9104 32.0943 59.1592 31.1083 59.1592C30.1223 59.1592 29.1333 58.9104 28.2458 58.4123ZM33.62 39.3704C32.1261 40.1401 31.1083 41.398 31.1083 41.398C31.1083 41.398 30.0905 40.1401 28.5966 39.3704C27.9382 39.0312 27.1872 38.7868 26.3902 38.7868C23.7849 38.7868 21.6722 40.7156 21.6722 43.0952C21.6722 45.0161 22.4978 49.5751 30.6252 54.1837C30.7708 54.2654 30.9379 54.3086 31.1083 54.3086C31.2787 54.3086 31.4458 54.2654 31.5914 54.1837C39.7188 49.5751 40.5445 45.0161 40.5445 43.0952C40.5445 40.7156 38.4317 38.7868 35.8264 38.7868C35.0294 38.7868 34.2784 39.0312 33.62 39.3704Z" fill="#DF829E" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.24047 22.8873C6.23325 22.8063 6.29715 22.7366 6.37858 22.7366H55.9909C56.0723 22.7366 56.1362 22.8063 56.129 22.8873L52.314 65.6468C52.3076 65.7181 52.2477 65.7729 52.1759 65.7729H10.1935C10.1217 65.7729 10.0618 65.7181 10.0554 65.6468L6.24047 22.8873ZM6.37858 16.5095H55.9909C59.7368 16.5095 62.676 19.716 62.3438 23.4396L58.5289 66.199C58.2359 69.4827 55.4793 72 52.1759 72H10.1935C6.89032 72 4.13357 69.4829 3.84059 66.1991L0.0256353 23.4396C-0.306596 19.7158 2.63286 16.5095 6.37858 16.5095Z" fill="#61C9B0" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5223 12.623C14.5223 5.36969 20.8767 0 28.0575 0H34.3119C41.4927 0 47.8471 5.36969 47.8471 12.623V25.3619C47.8471 27.0888 46.4542 28.4887 44.7359 28.4887C43.0176 28.4887 41.6246 27.0888 41.6246 25.3619V12.623C41.6246 9.38715 38.6451 6.25362 34.3119 6.25362H28.0575C23.7242 6.25362 20.7448 9.38715 20.7448 12.623V25.9178C20.7448 27.6447 19.3518 29.0446 17.6335 29.0446C15.9152 29.0446 14.5223 27.6447 14.5223 25.9178V12.623Z" fill="#61C9B0" />
  </svg>,
  phone: <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_23_261)">
      <path d="M9.16667 7.33337H16.5L20.1667 16.5L15.5833 19.25C17.5468 23.2312 20.7689 26.4533 24.75 28.4167L27.5 23.8334L36.6667 27.5V34.8334C36.6667 35.8058 36.2804 36.7385 35.5927 37.4261C34.9051 38.1137 33.9725 38.5 33 38.5C25.8487 38.0655 19.1036 35.0286 14.0375 29.9625C8.97142 24.8964 5.93459 18.1514 5.5 11C5.5 10.0276 5.88631 9.09495 6.57394 8.40732C7.26158 7.71968 8.19421 7.33337 9.16667 7.33337" stroke="#DF829E" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M27.5 12.8334C28.4725 12.8334 29.4051 13.2197 30.0927 13.9073C30.7804 14.5949 31.1667 15.5276 31.1667 16.5" stroke="#DF829E" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M27.5 5.5C30.4174 5.5 33.2153 6.65893 35.2782 8.72183C37.3411 10.7847 38.5 13.5826 38.5 16.5" stroke="#DF829E" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_23_261">
        <rect width="44" height="44" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  email: <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_23_279)">
      <path d="M34.8333 9.16663H9.16667C7.14162 9.16663 5.5 10.8082 5.5 12.8333V31.1666C5.5 33.1917 7.14162 34.8333 9.16667 34.8333H34.8333C36.8584 34.8333 38.5 33.1917 38.5 31.1666V12.8333C38.5 10.8082 36.8584 9.16663 34.8333 9.16663Z" stroke="#DF829E" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.5 12.8334L22 23.8334L38.5 12.8334" stroke="#DF829E" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_23_279">
        <rect width="44" height="44" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  'logo-mini': <svg width="28" height="33" viewBox="0 0 28 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6941 26.7936C12.6913 26.792 12.6885 26.7905 12.6858 26.7889C10.6434 25.6308 9.35104 24.3833 8.57625 23.1435C7.78889 21.8836 7.61386 20.7555 7.61386 19.986C7.61386 17.5599 9.7055 15.9153 11.8694 15.9153C12.7085 15.9153 13.4238 16.1832 13.9663 16.4822C14.5088 16.1832 15.2241 15.9153 16.0632 15.9153C18.2271 15.9153 20.3187 17.5599 20.3187 19.986C20.3187 20.7555 20.1437 21.8836 19.3563 23.1435C18.5815 24.3833 17.2892 25.6308 15.2468 26.7889C15.244 26.7905 15.2413 26.792 15.2385 26.7936C14.8441 27.0149 14.4045 27.1255 13.9663 27.1255C13.5281 27.1255 13.0885 27.0149 12.6941 26.7936ZM15.0826 18.3305C14.4187 18.6726 13.9663 19.2316 13.9663 19.2316C13.9663 19.2316 13.5139 18.6726 12.85 18.3305C12.5573 18.1798 12.2236 18.0711 11.8694 18.0711C10.7115 18.0711 9.77245 18.9284 9.77245 19.986C9.77245 20.8397 10.1394 22.8659 13.7516 24.9142C13.8163 24.9505 13.8905 24.9697 13.9663 24.9697C14.042 24.9697 14.1163 24.9505 14.181 24.9142C17.7932 22.8659 18.1601 20.8397 18.1601 19.986C18.1601 18.9284 17.2211 18.0711 16.0632 18.0711C15.709 18.0711 15.3752 18.1798 15.0826 18.3305Z" fill="#DF829E" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.91369 11.0047C2.91048 10.9687 2.93888 10.9378 2.97507 10.9378H25.0249C25.0611 10.9378 25.0895 10.9687 25.0863 11.0047L23.3908 30.0089C23.3879 30.0406 23.3613 30.0649 23.3294 30.0649H4.6706C4.63868 30.0649 4.61205 30.0406 4.60922 30.0089L2.91369 11.0047ZM2.97507 8.17017H25.0249C26.6898 8.17017 27.9961 9.59525 27.8485 11.2502L26.1529 30.2544C26.0227 31.7138 24.7976 32.8325 23.3294 32.8325H4.6706C3.20251 32.8325 1.97729 31.7138 1.84708 30.2544L0.151545 11.2502C0.00388727 9.59517 1.31031 8.17017 2.97507 8.17017Z" fill="#61C9B0" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.59469 6.44275C6.59469 3.21904 9.41889 0.83252 12.6103 0.83252H15.3901C18.5815 0.83252 21.4057 3.21904 21.4057 6.44275V12.1045C21.4057 12.872 20.7866 13.4941 20.0229 13.4941C19.2592 13.4941 18.6402 12.872 18.6402 12.1045V6.44275C18.6402 5.00458 17.3159 3.6119 15.3901 3.6119H12.6103C10.6844 3.6119 9.36023 5.00458 9.36023 6.44275V12.3515C9.36023 13.119 8.74114 13.7412 7.97746 13.7412C7.21378 13.7412 6.59469 13.119 6.59469 12.3515V6.44275Z" fill="#61C9B0" />
  </svg>,
  facebook: <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_98_443)">
      <path d="M12.8333 18.3333V25.6667H18.3333V38.5H25.6666V25.6667H31.1666L33 18.3333H25.6666V14.6667C25.6666 14.1804 25.8598 13.7141 26.2036 13.3703C26.5474 13.0265 27.0138 12.8333 27.5 12.8333H33V5.5H27.5C25.0688 5.5 22.7373 6.46577 21.0182 8.18485C19.2991 9.90394 18.3333 12.2355 18.3333 14.6667V18.3333H12.8333Z" stroke="white" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_98_443">
        <rect width="44" height="44" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  instagram: <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_98_446)">
      <path d="M29.3333 7.33331H14.6667C10.6166 7.33331 7.33334 10.6166 7.33334 14.6666V29.3333C7.33334 33.3834 10.6166 36.6666 14.6667 36.6666H29.3333C33.3834 36.6666 36.6667 33.3834 36.6667 29.3333V14.6666C36.6667 10.6166 33.3834 7.33331 29.3333 7.33331Z" stroke="white" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M22 27.5C25.0376 27.5 27.5 25.0376 27.5 22C27.5 18.9624 25.0376 16.5 22 16.5C18.9624 16.5 16.5 18.9624 16.5 22C16.5 25.0376 18.9624 27.5 22 27.5Z" stroke="white" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M30.25 13.75V13.751" stroke="white" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_98_446">
        <rect width="44" height="44" fill="white" />
      </clipPath>
    </defs>
  </svg>,
   instagrampurple: <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
   <g clip-path="url(#clip0_98_446)">
     <path d="M29.3333 7.33331H14.6667C10.6166 7.33331 7.33334 10.6166 7.33334 14.6666V29.3333C7.33334 33.3834 10.6166 36.6666 14.6667 36.6666H29.3333C33.3834 36.6666 36.6667 33.3834 36.6667 29.3333V14.6666C36.6667 10.6166 33.3834 7.33331 29.3333 7.33331Z" stroke="#DF829E" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round" />
     <path d="M22 27.5C25.0376 27.5 27.5 25.0376 27.5 22C27.5 18.9624 25.0376 16.5 22 16.5C18.9624 16.5 16.5 18.9624 16.5 22C16.5 25.0376 18.9624 27.5 22 27.5Z" stroke="#DF829E" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round" />
     <path d="M30.25 13.75V13.751" stroke="#DF829E" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round" />
   </g>
   <defs>
     <clipPath id="clip0_23_279">
       <rect width="44" height="44" fill="white" />
     </clipPath>
   </defs>
 </svg>
}