import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import Home from './pages/Home';
import Terms from './pages/Terms';
import Reset from './pages/Reset';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  const theme = {
    white: '#FFFFFF',
    purple: '#4D3277',
    darkgray: '#333333',
    green: '#61C9B0',
    purplebg: '#563785',
    pinkbg: '#D66EB3',
    gray: '#777777',
    lightwhite: '#EBEBEB',
    mediumpurple: '#6D4D9E',
    darkpurple: '#412A63',
    border: '#CCCCCC',
    lightgray: '#999999',
    borderPurple: '#6E47AA',
    black: '#000000',
    silver: '#BDBDBD'
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/reset-password" element={<Reset />} />
          {/* Redirecionamento para / se o usuário acessar um caminho inválido */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
