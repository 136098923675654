import styled from "styled-components";

export const Wrapper = styled.div`
  padding-top: 6.7rem;
  padding-bottom: 5.7rem;
  background-color: ${({theme}) => theme.darkpurple};
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3.2rem;
  p {
    color: ${({theme}) => theme.white};
  }
  >div {
    display: flex;
    align-items: center;
    p {
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 2.4rem;
      letter-spacing: -0.04rem;
      margin-left: .8rem;
    }
  }

  .rights {
    font-size: 1.6rem;
    line-height: 1.6rem;
    letter-spacing: 0.05rem;
    font-weight: 300;
    text-align: center;
  }
`