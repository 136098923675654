import { Icon } from "../icon/icon";
import { Wrapper } from "./Header.styled";

export const Header = () => {
  const email = 'contato@foodfix.com.br';
  const options = [
    {
      label: 'Fale conosco',
      description: '(41) 99212-0946',
      icon: 'phone',
      action: () => {         
        window.open(`https://wa.me/5541992120946`);
      }
    },
    {
      label: 'Instagram',
      description: '@foodfix.br',
      icon: 'instagrampurple',  // Correção aqui
      action: () => {
        window.open(`https://www.instagram.com/foodfix.br`);
      }
    },
    {
      label: 'E-mail',
      description: email,
      icon: 'email',
      action: () => {
        window.open(`mailto:${email}`);
      }
    }
  ];

  return (
    <Wrapper>
      <div className="logo">
      <img src="/imgs/FoodFix.png" alt="Logo" />
      </div>

      <div className="contact">
        {options.map(({ label, icon, description, action }: any, key: number) => (
          <div onClick={action} key={key + 'contact'}>
            <Icon icon={icon} />
            <div className="summary">
              <p className="label">
                {label}
              </p>
              <p className="description">
                {description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </Wrapper>
  );
}
