import { Icon } from '../icon/icon'
import {
  Wrapper
} from './Footer.styles'

export const Footer = () => {
  return <Wrapper>
    <div>
      <Icon icon="logo-mini" />
      <p>
        FoodFix | 2024
      </p>
    </div>
    <p className="rights">
      2024 | 
      56.967.677/0001-58 - FoodFix Soluções e serviços LTDA. Todos direitos reservados 
    </p>
  </Wrapper>
}