import styled from 'styled-components';

export const LogoContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

export const ContentContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

export const Title = styled.h1`
  text-align: center;
`;
