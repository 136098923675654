import Button from "../Button"
import { Icon } from "../icon/icon"
import { Wrapper } from "./Banner.styles"

export const Banner = () => {
  const options = [
    '/imgs/gplay.png',
    '/imgs/apple.png'
  ]

  const optionsLinks = [
    'https://play.google.com/store/apps/details?id=com.bentoappflutter',
    'https://apps.apple.com/us/app/bent%C3%B4-app/id6498396819'
  ]

  const scrollToForm = () => {
    //using timeout to garantee the target element was correctly rendered
    setTimeout(() => {
      const element = document.getElementById("form")
      if(!element) return
      element?.scrollIntoView()
    }, 16)
  }


  return <Wrapper>
    <div className="splash">
      
    </div>
    <pre className="label">
      <span>Conectando</span>{`\n`}
      estabelecimentos e{`\n`}
      <span>clientes</span> através de{`\n`}
      bons descontos!
    </pre>

    <div className="mobile">
      <img src="/imgs/mobile_bg.png" alt="" className="bg" />
      <div className="box">
        <div className="header">
          <p className="title">
            Faça parte disso!
          </p>
          <p className="description">
            Para utilizar o FoodFix  baixe o aplicativo.
          </p>
        </div>
        <div className="body">
          <div className="image__row">
            {
              options.map((path, key) => <img 
              onClick={() => window.open(optionsLinks[key])} key={key + 'img'}
                src={path}
                alt=""
              />)
            }
          </div>
          <p className="call">
            Não é nosso parceiro?{`\n`}
          </p>
          <p className="call">
          <span>Baixe o App e cadastre-se</span>
          </p>
          <Button action={scrollToForm}>
            Seja nosso parceiro
          </Button>
        </div>
      </div>
    </div>

  </Wrapper>
}