import AboutUs from "../../components/AboutUs"
import Banner from "../../components/Banner"
import Contact from "../../components/Contact"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import Reset from "../../components/Header"
import ListSection from "../../components/ListSection"
import { Wrapper } from "./Home.styles"

export const Home = () => {
  return <Wrapper>
    <Header />
    <Banner />
    <Footer />
  </Wrapper>
}