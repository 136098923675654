import { PROJECT_ICONS } from './catalog';

const iconCatalog: any = {
  ...PROJECT_ICONS
};

export const Icon = (props: {
  icon: string;
  color?: string;
}) => {
  const targetIcon: any = iconCatalog[props.icon];

  if (!targetIcon) return <div>Ícone não encontrado</div>; // Mensagem para depuração

  return (
    <>
      {typeof targetIcon === 'function' ? targetIcon(props.color) : targetIcon}
    </>
  );
};
